:root {
  --app-color-brand-h: 147;
  --app-color-brand-s: 65%;
  --app-color-brand-l: 41%;
  --app-color-brand: hsl(var(--app-color-brand-h), var(--app-color-brand-s), var(--app-color-brand-l));
  --app-color-brand-highlight: hsl(var(--app-color-brand-h), calc(var(--app-color-brand-s) - 12%), calc(var(--app-color-brand-l) + 7%));
  --app-color-brand-dark-h: 158;
  --app-color-brand-dark-s: 94%;
  --app-color-brand-dark-l: 20%;
  --app-color-brand-dark: hsl(var(--app-color-brand-dark-h), var(--app-color-brand-dark-s), var(--app-color-brand-dark-l));
  --app-color-brand-light-h: 98;
  --app-color-brand-light-s: 52%;
  --app-color-brand-light-l: 72%;
  --app-color-brand-light: hsl(var(--app-color-brand-light-h), var(--app-color-brand-light-s), var(--app-color-brand-light-l));
  --app-color-brand-accent-h: 357;
  --app-color-brand-accent-s: 85%;
  --app-color-brand-accent-l: 60%;
  --app-color-brand-accent: hsl(var(--app-color-brand-accent-h), var(--app-color-brand-accent-s), var(--app-color-brand-accent-l));
  --app-color-brand-accent-highlight: hsl(calc(var(--app-color-brand-accent-h) + 3), calc(var(--app-color-brand-accent-s) - 15%), calc(var(--app-color-brand-accent-l) + 9%));
  --app-color-brand-accent-dark-h: 351;
  --app-color-brand-accent-dark-s: 64%;
  --app-color-brand-accent-dark-l: 31%;
  --app-color-brand-accent-dark: hsl(var(--app-color-brand-accent-dark-h), var(--app-color-brand-accent-dark-s), var(--app-color-brand-accent-dark-l));
  --app-color-brand-accent-light-h: 349;
  --app-color-brand-accent-light-s: 83%;
  --app-color-brand-accent-light-l: 89%;
  --app-color-brand-accent-light: hsl(var(--app-color-brand-accent-light-h), var(--app-color-brand-accent-light-s), var(--app-color-brand-accent-light-l));
  --app-color-off-white: hsl(69, 19%, 93%);
  --app-text-color: hsl(calc(var(--app-color-brand-h) + 40), 5%, 31%);
  --app-text-color-dark: hsl(calc(var(--app-color-brand-h) + 40), 7%, 22%);
  --app-color-neutral-h: 165;
  --app-color-neutral-white: hsl(var(--app-color-neutral-h), 0%, 100%);
  --app-color-neutral-lightest: hsl(var(--app-color-neutral-h), 7%, 95%);
  --app-color-neutral-lighter: hsl(var(--app-color-neutral-h), 7%, 89%);
  --app-color-neutral-light: hsl(calc(var(--app-color-neutral-h) + 15), 4%, 79%);
  --app-color-neutral: hsl(calc(var(--app-color-neutral-h) + 15), 2%, 58%);
  --app-color-neutral-dark: hsl(calc(var(--app-color-neutral-h) + 22), 5%, 31%);
  --app-color-neutral-darker: hsl(calc(var(--app-color-neutral-h) + 22), 7%, 22%);
  --app-color-neutral-darkest: hsl(calc(var(--app-color-neutral-h) + 24), 9%, 15%);
  --app-color-neutral-black: hsl(calc(var(--app-color-neutral-h) + 24), 0%, 0%);
  --app-color-shadow-line: hsla(189, 9%, 15%, .15);
  --app-shadow-sticker: drop-shadow(1px 1px 0 var(--app-color-shadow-line)) drop-shadow(0 0 1px var(--app-color-shadow-line));
  --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);
  --app-shadow-inset-low: inset 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-inset-mid: inset 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-inset-high: inset 0 5px 10px var(--app-color-shadow-line);

  --app-background: var(--app-color-off-white);

  --app-header-height: 3em;
  --app-header-background: hsl(var(--app-color-brand-h), 0%, 100%);
  --app-header-background-focus: hsl(var(--app-color-brand-h), 10%, 90%);
  --app-header-background-active: hsl(var(--app-color-brand-h), var(--app-color-brand-s), var(--app-color-brand-l));
  --app-header-background-active-focus: hsl(var(--app-color-brand-h), var(--app-color-brand-s), calc(var(--app-color-brand-l) + 5%));
  --app-header-color: var(--app-text-color-dark);
  --app-header-color-focus: var(--app-header-color);
  --app-header-color-active: hsl(var(--app-color-brand-h), 0%, 100%);
  --app-header-color-active-focus: var(--app-header-color-active);
}

@font-face {
  font-family: texts;
  font-display: swap;
  src:  url('../fonts/adelle-sans/adelle-sans-regular.woff2') format('woff2'),
        url('../fonts/adelle-sans/adelle-sans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src:  url('../fonts/adelle-sans/adelle-sans-light.woff2') format('woff2'),
        url('../fonts/adelle-sans/adelle-sans-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

body {
  background: var(--app-background);
}

body, input, textarea, select, button {
  font-family: texts, Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
}

h1, h2, h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  letter-spacing: -.03em;
  line-height: 1.1;
}

.app-logo::after {
  content: url('../svgs/logo-paf.svg');
  width: 5.5em;
  margin-top: .5em;
}